import React from 'react'

import {    
  Divider,
  Grid,
  Header,
  Segment,
  Icon
} from 'semantic-ui-react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import HomepageLayout from '../components/HomepageLayout'
import FluidImageServico from '../components/images/FluidImageServico'

const styleDivider = {
  margin: '3em 0em',
  textTransform: 'uppercase',
  color:'gray'
}

const Servicos = () => (
  <Segment style={{
      padding: '8em 0em'
    }} vertical="vertical">
    <Grid container="container" stackable="stackable" verticalAlign='middle'>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as='h3' style={{
              fontSize: '2em'
            }}>
            Serviços
          </Header>
          <p style={{
              fontSize: '1.33em'
            }}>
            Fornecemos uma variedade de serviços com o objetivo de apoiar seu negócio em assuntos relacionados a técnologia da informação.
          </p>
        </Grid.Column>
        <Grid.Column floated='rigth' width={6}>
          <FluidImageServico />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
        <Divider as='a' href='#consultoria' className='header' horizontal="horizontal" style={styleDivider}>
           <Icon className='handshake outline'/> Consultoria
        </Divider>
        <Header as='h3' style={{
            fontSize: '2em'
          }}>
          Colsultor expecialista de TI
        </Header>
        <p style={{
            fontSize: '1.33em'
          }}>
          Realizamos consultorias em desenvolvimento de software, ministramos cursos sobre tecnologias e metodologias
        </p>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
        <Divider as='a' href='#terceirize' className='header' horizontal="horizontal" style={styleDivider}>
           <Icon className='address card'/> Terceirização
        </Divider>
        <Header as='h3' style={{
            fontSize: '2em'
          }}>
          Realizamos tarefas de desenvolvimento
        </Header>
        <p style={{
            fontSize: '1.33em'
          }}>
          Repasse projetos expecificos ou demandas completas para nossa equipe. Discuta as tecnologias a serem utlizadas custos e prazos.
        </p>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
        <Divider as='a' href='#arquitetura' className='header' horizontal="horizontal" style={styleDivider}>
           <Icon className='compass outline'/> Arquitetura
        </Divider>
        <Header as='h3' style={{
            fontSize: '2em'
          }}>
          Desenvolva a partir de projetos iniciais
        </Header>
        <p style={{
            fontSize: '1.33em'
          }}>
          Definimos arquiteturas modernas e sustentáveis a partir de suas necessidades, sugerimos infraestruturas e ferramentas de apoio.
        </p>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
        <Divider as='a' href='#poc' className='header' horizontal="horizontal" style={styleDivider}>
           <Icon className='flag'/> POCs
        </Divider>
        <Header as='h3' style={{
            fontSize: '2em'
          }}>
          Provas de conceito
        </Header>
        <p style={{
            fontSize: '1.33em'
          }}>
          Validamos ideias, testamos cenários expecificos, monitoramos resultados que podem ajudar em tomadas de decisão.
        </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
)

const ServicosPage = () => (
  <Layout>
    <SEO title="Serviços" lang="pt" keywords={['software', 'tecnology', 'web', 'b2b', 'e-comerce']}  />
    <HomepageLayout showLogo={false} page='servicos'>
      <Servicos />
    </HomepageLayout>
  </Layout>
)

export default ServicosPage
